import {
  GET_ROLE_FAIL,
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  PERMISSION_LIST_FAIL,
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  STORE_ADDRESS_MAP_CONFIRM,
  STORE_DELETE_FAIL,
  STORE_DELETE_REQUEST,
  STORE_DELETE_RESET,
  STORE_DELETE_SUCCESS,
  STORE_DETAILS_FAIL,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_RESET,
  STORE_DETAILS_SUCCESS,
  STORE_LIST_FAIL,
  STORE_LIST_MANAGER_FAIL,
  STORE_LIST_MANAGER_REQUEST,
  STORE_LIST_MANAGER_SUCCESS,
  STORE_LIST_REQUEST,
  STORE_LIST_ROLES_FAIL,
  STORE_LIST_ROLES_REQUEST,
  STORE_LIST_ROLES_SUCCESS,
  STORE_LIST_SUCCESS,
  STORE_REGISTER_FAIL,
  STORE_REGISTER_REQUEST,
  STORE_REGISTER_SUCCESS,
  STORE_SAVE_PAYMENT,
  STORE_SAVE_STORE_INFO,
  STORE_SAVE_TAX_TYPE,
  STORE_SIGNIN_FAIL,
  STORE_SIGNIN_REQUEST,
  STORE_SIGNIN_SUCCESS,
  STORE_SIGNOUT,
  STORE_TOPSELLERS_LIST_FAIL,
  STORE_TOPSELLERS_LIST_REQUEST,
  STORE_TOPSELLERS_LIST_SUCCESS,
  STORE_UPDATE_FAIL,
  STORE_UPDATE_PROFILE_FAIL,
  STORE_UPDATE_PROFILE_REQUEST,
  STORE_UPDATE_PROFILE_RESET,
  STORE_UPDATE_PROFILE_SUCCESS,
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_RESET,
  STORE_UPDATE_SUCCESS,
} from '../constants/sellerConstants';

export const storeRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_REGISTER_REQUEST:
      return { loading: true };
    case STORE_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case STORE_REGISTER_FAIL:
      return { loading: false, error: action.payload };
      case STORE_SAVE_PAYMENT:
      return { ...state, shopPayments: action.payload };
    case STORE_SAVE_TAX_TYPE:
      return { ...state, shopTax: action.payload };
    case STORE_SAVE_STORE_INFO:
      return { ...state, shopInfo: action.payload };
    default:
      return state;
  }
};


export const storeDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case STORE_DETAILS_REQUEST:
      return { loading: true };
    case STORE_DETAILS_SUCCESS:
      return { loading: false, store: action.payload };
    case STORE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case STORE_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};
export const storeUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case STORE_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case STORE_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case STORE_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};
export const storeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_UPDATE_REQUEST:
      return { loading: true };
    case STORE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case STORE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STORE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};


export const permissionListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PERMISSION_LIST_REQUEST:
      return { loading: true };
    case PERMISSION_LIST_SUCCESS:
      return { loading: false, permissions: action.payload };
    case PERMISSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getRoleReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GET_ROLE_REQUEST:
      return { loading: true };
    case GET_ROLE_SUCCESS:
      return { loading: false, role: action.payload };
    case GET_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_DELETE_REQUEST:
      return { loading: true };
    case STORE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case STORE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case STORE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const storeManagerListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case STORE_LIST_MANAGER_REQUEST:
      return { loading: true };
    case STORE_LIST_MANAGER_SUCCESS:
      return { 
        loading: false, 
        users: action.payload.users ,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case STORE_LIST_MANAGER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const storeRolesListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case STORE_LIST_ROLES_REQUEST:
      return { loading: true };
    case STORE_LIST_ROLES_SUCCESS:
      return { 
        loading: false, 
        roles: action.payload.roles ,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case STORE_LIST_ROLES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userAddressMapReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_ADDRESS_MAP_CONFIRM:
      return { address: action.payload };
    default:
      return state;
  }
};
