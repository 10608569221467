import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import {
  sellerRoot,
} from './constants/defaultValues';

import { ProtectedRoute } from './helpers/authHelper';
import withClearCache from "./ClearCache";

const ViewApp = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/app')
);
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/error')
);
const ViewAuth = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/auth')
);

const ViewUnauthorized = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/unauthorized')
);

const ViewUnverifyed = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/unverifyed')
);

const ViewWelcome = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/welcome')
);


function App() {
  return <ClearCacheComponent />;
}


const ClearCacheComponent = withClearCache(MainApp);



function MainApp() {

  return (
    <>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <ScrollContext>
            <Switch>
              <ProtectedRoute
                path={sellerRoot}
                component={ViewApp}
              />
              <Route
                path="/error"
                exact
                render={(props) => <ViewError {...props} />}
              />
              <Route
                path="/unauthorized"
                exact
                render={(props) => <ViewUnauthorized {...props} />}
              />
              <Route
                path="/unverifyed"
                exact
                render={(props) => <ViewUnverifyed {...props} />}
              />
              <Route
                path="/bienvenue"
                exact
                render={(props) => <ViewWelcome {...props} />}
              />

              <Route
                path="/auth"
                render={(props) => <ViewAuth {...props} />}
              />
              <Redirect exact from="/" to="/app" />
              <Redirect to="/error" />
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Suspense>
    </>
  )
}






export default App;