export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';

export const TOP_CATEGORY_LIST_REQUEST = 'TOP_CATEGORY_LIST_REQUEST';
export const TOP_CATEGORY_LIST_SUCCESS = 'TOP_CATEGORY_LIST_SUCCESS';
export const TOP_CATEGORY_LIST_FAIL = 'TOP_CATEGORY_LIST_FAIL';

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST';
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS';
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL';

export const PRESTATION_CATEGORY_DETAILS_REQUEST =
  'PRESTATION_CATEGORY_DETAILS_REQUEST';
export const PRESTATION_CATEGORY_DETAILS_SUCCESS =
  'PRESTATION_CATEGORY_DETAILS_SUCCESS';
export const PRESTATION_CATEGORY_DETAILS_FAIL =
  'PRESTATION_CATEGORY_DETAILS_FAIL';

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST';
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL';
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET';

export const CATEGORY_REVIEW_CREATE_REQUEST = 'CATEGORY_REVIEW_CREATE_REQUEST';
export const CATEGORY_REVIEW_CREATE_SUCCESS = 'CATEGORY_REVIEW_CREATE_SUCCESS';
export const CATEGORY_REVIEW_CREATE_FAIL = 'CATEGORY_REVIEW_CREATE_FAIL';
export const CATEGORY_REVIEW_CREATE_RESET = 'CATEGORY_REVIEW_CREATE_RESET';

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET';

export const CATEGORY_CATEGORY_LIST_REQUEST = 'CATEGORY_CATEGORY_LIST_REQUEST';
export const CATEGORY_CATEGORY_LIST_SUCCESS = 'CATEGORY_CATEGORY_LIST_SUCCESS';
export const CATEGORY_CATEGORY_LIST_FAIL = 'CATEGORY_CATEGORY_LIST_FAIL';
