import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { getCurrentUser } from './helpers/Utils';
import { AllBrandListReducer, brandCreateReducer, brandDeleteReducer, brandDetailsReducer, brandListReducer, brandUpdateReducer } from './reducers/brandReducers';
import { AllCategoryListReducer, categoryCreateReducer, categoryDeleteReducer, categoryDetailsReducer, categoryListReducer, categoryUpdateReducer } from './reducers/categoryReducers';
import {
  changeOrderStatusReducer,
  createPackageReducer,
  getPackageDetailsReducer,
  orderCreateReducer,
  orderDeleteReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderListReducer,
  orderMineListReducer,
  orderPayReducer,
  orderSummaryReducer,
} from './reducers/orderReducers';
import {
  productCategoryListReducer,
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productFirstCategoryListReducer,
  productListReducer,
  productReviewCreateReducer,
  productSecondaryCategoryListReducer,
  productSousCategory1ListReducer,
  productSousCategory2ListReducer,
  productThirdCategoryListReducer,
  productUpdateReducer,
  SaveCategoriesCreateProductsReducer,
} from './reducers/productReducers';
import { storeDetailsReducer, storeManagerListReducer, storeRegisterReducer, storeRolesListReducer, storeUpdateReducer } from './reducers/sellerReducers';
import {
  forgotPasswordReducer,
  getRoleReducer,
  permissionListReducer,
  resetPasswordReducer,
  updateUserStatusReducer,
  userAddressMapReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userRegisterReducer,
  userSigninReducer,
  userTopSellerListReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from './reducers/userReducers';

const initialState = {
  userSignin: {
    userInfo: getCurrentUser(),
  }
};
const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productCreateSaveCategories: SaveCategoriesCreateProductsReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  categoryList: categoryListReducer,
  productFirstCategoryList: productFirstCategoryListReducer,
  productCategoryList: productCategoryListReducer,
  productSecondaryCategoryList: productSecondaryCategoryListReducer,
  productThirdCategoryList: productThirdCategoryListReducer,
  allCategoryList: AllCategoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  allBrandList: AllBrandListReducer,
  brandList: brandListReducer,
  brandDetails: brandDetailsReducer,
  brandCreate: brandCreateReducer,
  brandUpdate: brandUpdateReducer,
  brandDelete: brandDeleteReducer,
  userSignin: userSigninReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  userRegister: userRegisterReducer,
  orderCreate: orderCreateReducer,
  packageCreate: createPackageReducer,
  packageDetails: getPackageDetailsReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderMineList: orderMineListReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  updateUserStatus: updateUserStatusReducer,
  userUpdate: userUpdateReducer,
  storeRegister: storeRegisterReducer,
  storeUpdate: storeUpdateReducer,
  storeDetails: storeDetailsReducer,
  storeManagerList: storeManagerListReducer,
  storeRolesList: storeRolesListReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  orderDeliver: orderDeliverReducer,
  changeOrderStatus: changeOrderStatusReducer,
  userList: userListReducer,
  permissionList: permissionListReducer,
  userRole: getRoleReducer,
  userDelete: userDeleteReducer,
  userTopSellersList: userTopSellerListReducer,
  productReviewCreate: productReviewCreateReducer,
  userAddressMap: userAddressMapReducer,
  orderSummary: orderSummaryReducer,
});
const composeEnhancer = process.env.NODE_ENV === "development"
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null || compose;

//const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
