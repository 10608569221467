 import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { ToastContainer } from 'react-toastify';

import * as serviceWorker from './serviceWorker';
import { TicketContextProvider } from './services/ticket/ticket.context';
import { PackageContextProvider } from './services/package/package.context';


ReactDOM.render(
    <Provider store={store}>
      <TicketContextProvider>
      <PackageContextProvider>
        <Suspense fallback={<div className="loading" />}>
        <App />
        <ToastContainer/>
      </Suspense>
      </PackageContextProvider>
      </TicketContextProvider>
    </Provider>,
    document.getElementById('root')
    );
    serviceWorker.register();




