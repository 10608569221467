export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST';
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS';
export const BRAND_LIST_FAIL = 'BRAND_LIST_FAIL';


export const BRAND_DETAILS_REQUEST = 'BRAND_DETAILS_REQUEST';
export const BRAND_DETAILS_SUCCESS = 'BRAND_DETAILS_SUCCESS';
export const BRAND_DETAILS_FAIL = 'BRAND_DETAILS_FAIL';

export const BRAND_CREATE_REQUEST = 'BRAND_CREATE_REQUEST';
export const BRAND_CREATE_SUCCESS = 'BRAND_CREATE_SUCCESS';
export const BRAND_CREATE_FAIL = 'BRAND_CREATE_FAIL';
export const BRAND_CREATE_RESET = 'BRAND_CREATE_RESET';


export const BRAND_UPDATE_REQUEST = 'BRAND_UPDATE_REQUEST';
export const BRAND_UPDATE_SUCCESS = 'BRAND_UPDATE_SUCCESS';
export const BRAND_UPDATE_FAIL = 'BRAND_UPDATE_FAIL';
export const BRAND_UPDATE_RESET = 'BRAND_UPDATE_RESET';

export const BRAND_DELETE_REQUEST = 'BRAND_DELETE_REQUEST';
export const BRAND_DELETE_SUCCESS = 'BRAND_DELETE_SUCCESS';
export const BRAND_DELETE_FAIL = 'BRAND_DELETE_FAIL';
export const BRAND_DELETE_RESET = 'BRAND_DELETE_RESET';

export const BRAND_BRAND_LIST_REQUEST = 'BRAND_BRAND_LIST_REQUEST';
export const BRAND_BRAND_LIST_SUCCESS = 'BRAND_BRAND_LIST_SUCCESS';
export const BRAND_BRAND_LIST_FAIL = 'BRAND_BRAND_LIST_FAIL';
