import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from './Utils';
import { isAuthGuardActive } from '../constants/defaultValues';

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const currentUser = getCurrentUser();
 
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      
      if (currentUser && currentUser.active && currentUser.seller) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/bienvenue',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};

export { ProtectedRoute };
