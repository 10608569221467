import axios from "axios";
import React, { useState, createContext, useReducer, useEffect } from "react";

export const TicketContext = createContext();

export const initialState = {
  tickets: [],
  ticket: null,
  error: false,
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        ...{ loading: true },
      };

    case "GET_TICKETS_SUCCESS":
      return {
        ...state,
        ...{
          loading: false,
          tickets: action.payload.tickets,
          pages: action.payload.pages,
          page: action.payload.page,
        },
      };

    case "GET_TICKETS_ERROR":
      return {
        ...state,
        ...{ error: action.error },
      };
    case "GET_TICKET_DETAILS":
      return {
        ...state,
        ...{ loading: true },
      };

    case "GET_TICKET_DETAILS_SUCCESS":
      return {
        ...state,
        ...{ ticket: action.payload, loading: false },
      };

    case "GET_TICKET_DETAILS_ERROR":
      return {
        ...state,
        ...{ error: action.error,loading:false },
      };
    case "CLOSE_TICKET":
      return {
        ...state,
        ...{ loading: true },
      };

    case "CLOSE_TICKET_SUCCESS":
      return {
        ...state,
        ...{ ticket: action.payload, loading: false },
      };

    case "CLOSE_TICKET_ERROR":
      return {
        ...state,
        ...{ error: action.error,loading:false },
      };
    case "CREATE_TICKET":
      return {
        ...state,
        ...{ loading: true },
      };

    case "CREATE_TICKET_SUCCESS":
      return {
        ...state,
        ...{ ticket: action.payload, success: true, loading: false },
      };
    case "CREATE_TICKET_RESET":
      return {
        ...state,
        success:false
      };

    case "CREATE_TICKET_FAIL":
      return {
        ...state,
        ...{ error: action.payload,loading: false },
      };
    case "UPDATE_TICKET":
      return {
        ...state,
        ...{ loading: true },
      };

    case "UPDATE_TICKET_SUCCESS":
      return {
        ...state,
        ...{ loading: true, success: true },
      };

    case "UPDATE_TICKET_FAIL":
      return {
        ...state,
        ...{ error: action.payload ,loading: false},
      };

      case "UPDATE_REPLY_TICKET":
      return {
        ...state,
        ...{ loading: true },
      };

    case "UPDATE_REPLY_TICKET_SUCCESS":
      return {
        ...state,
        ...{ loading: false, success: true },
      };

    case "UPDATE_REPLY_TICKET_FAIL":
      return {
        ...state,
        ...{ error: action.payload ,loading: false},
      };
      case "UPDATE_REPLY_TICKET_RESET":
      return {
        ...state, success:false};

    default:
      return state;
  }
}

export const TicketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getTickets = async ({
    pageNumber = '',
    name = '',
    pageSize = '',
    status = '',
    ticket = '',
    min = 0,
    max = 0,
  }) => {
    dispatch({
      type: "GET_TICKETS"
    });

    try {
      const { data } = await axios.get(
        `/api/tickets?pageNumber=${pageNumber}&name=${name}&status=${status}&ticket=${ticket}&min=${min}&max=${max}&pageSize=${pageSize}`
      );
      dispatch({
        type: "GET_TICKETS_SUCCESS",
        payload: data,
      });

    } catch (error) {
      const errordata =
        error.response && error.response.data.message
          ? error.response.data.message
          : error

      dispatch({
        type: "GET_TICKETS_ERROR",
        payload: errordata,
      });
    }
  };

  const createTicket = async (ticket) => {
    dispatch({ type: "CREATE_TICKET", payload: ticket });

    try {
      const { data } = await axios.post(
        '/api/tickets',
        {ticket}
      );
      dispatch({
        type: "CREATE_TICKET_SUCCESS",
        payload: data,
      });

    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "CREATE_TICKET_FAIL", payload: message });
    }
  };
  const updateReplyTicket = async (ticket) => {
    dispatch({ type: "UPDATE_REPLY_TICKET", payload: ticket });

    try {
      const { data } = await axios.put(`/api/tickets/${ticket._id}`, ticket);
      dispatch({ type: "UPDATE_REPLY_TICKET_SUCCESS", payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "UPDATE_REPLY_TICKET_FAIL", payload: message });
    }
  };
  const updateTicket = async (ticket) => {
    dispatch({ type: "UPDATE_TICKET", payload: ticket });

    try {
      const { data } = await axios.put(`/api/tickets/${ticket._id}`, ticket);
      dispatch({ type: "UPDATE_TICKET_SUCCESS", payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "UPDATE_TICKET_FAIL", payload: message });
    }
  };

  const closeTicket = async (_id) => {
    dispatch({
      type: "CLOSE_TICKET",
    });
    try {
      const { data } = await axios.patch(`/api/tickets/close-ticket/${_id}`);
      dispatch({ type: "CLOSE_TICKET_SUCCESS", payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: "CLOSE_TICKET_FAIL",
        error: message
      });
    }
  };

 /*  useEffect(() => {
    getTickets({})
  }, []);
 */
  return (
    <TicketContext.Provider
      value={{
        state,
        dispatch,
        createTicket,
        closeTicket,
        updateTicket,
        updateReplyTicket
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
