import axios from "axios";
import React, { useState, createContext, useReducer, useEffect } from "react";

export const PackageContext = createContext();

export const initialState = {
  package: null,
  error: false,
  errorcreate: false,
  loading: false,
  loadingcreate: false,
  success: false
};

function reducer(state, action) {
  switch (action.type) {
    case "CREATE_PACKAGE_REQUEST":
      return { loadingcreate: true };
    case "CREATE_PACKAGE_SUCCESS":
      return { loadingcreate: false, success: true, package: action.payload };
    case "CREATE_PACKAGE_FAIL":
      return { loadingcreate: false, errorcreate: action.payload };
    case "CREATE_PACKAGE_RESET":
      return {success: false,errorcreate: false,loadingcreate: false};
      case "PACKAGE_DETAILS_REQUEST":
        return { loading: true };
      case "PACKAGE_DETAILS_SUCCESS":
        return { loading: false, package: action.payload };
      case "PACKAGE_DETAILS_FAIL":
        return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const PackageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);


  const createPackage = async (order) => {
    dispatch({ type: "CREATE_PACKAGE_REQUEST", payload: order });
    try { 
      const { data } = await axios.post('/api/package', {
        order:order._id,carrier:order.carrier,seller:order.seller,
        itemsPrice:order.itemsPrice,shippingPrice:order.shippingPrice,
        totalPrice:order.totalPrice,
        lastStatus:order.lastStatus
      });
      dispatch({ type: "CREATE_PACKAGE_SUCCESS", payload: data.order });
  
    } catch (error) {
      dispatch({
        type: "CREATE_PACKAGE_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const detailsPackage = (orderId) => async (dispatch) => {
    dispatch({ type: "PACKAGE_DETAILS_REQUEST", payload: orderId });
   
    try {
      const { data } = await axios.get(`/api/package/order/${orderId}`);
      dispatch({ type: "PACKAGE_DETAILS_SUCCESS", payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "PACKAGE_DETAILS_FAIL", payload: message });
    }
  };

 

 /*  useEffect(() => {
    getTickets({})
  }, []);
 */
  return (
    <PackageContext.Provider
      value={{
        state,
        dispatch,
        createPackage
        
      }}
    >
      {children}
    </PackageContext.Provider>
  );
};
