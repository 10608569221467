import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
const render = () => {
  import(`./assets/scss/app.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();


