export const STORE_REGISTER_REQUEST = 'STORE_REGISTER_REQUEST';
export const STORE_REGISTER_SUCCESS = 'STORE_REGISTER_SUCCESS';
export const STORE_REGISTER_FAIL = 'STORE_REGISTER_FAIL';

export const STORE_REGISTER_USER_REQUEST = 'STORE_REGISTER_USER_REQUEST';
export const STORE_REGISTER_USER_SUCCESS = 'STORE_REGISTER_USER_SUCCESS';
export const STORE_REGISTER_USER_FAIL = 'STORE_REGISTER_USER_FAIL';


export const STORE_SAVE_PAYMENT = 'STORE_SAVE_PAYMENT';
export const STORE_SAVE_TAX_TYPE = 'STORE_SAVE_TAX_TYPE';
export const STORE_SAVE_STORE_INFO = 'STORE_SAVE_STORE_INFO';

export const STORE_SIGNIN_REQUEST = 'STORE_SIGNIN_REQUEST';
export const STORE_SIGNIN_SUCCESS = 'STORE_SIGNIN_SUCCESS';
export const STORE_SIGNIN_FAIL = 'STORE_SIGNIN_FAIL';

export const STORE_CREDENTIAL_REQUEST = 'STORE_CREDENTIAL_REQUEST';
export const STORE_CREDENTIAL_SUCCESS = 'STORE_CREDENTIAL_SUCCESS';
export const STORE_CREDENTIAL_FAIL = 'STORE_CREDENTIAL_FAIL';

export const STORE_SIGNOUT = 'STORE_SIGNOUT';

export const STORE_DETAILS_REQUEST = 'STORE_DETAILS_REQUEST';
export const STORE_DETAILS_SUCCESS = 'STORE_DETAILS_SUCCESS';
export const STORE_DETAILS_FAIL = 'STORE_DETAILS_FAIL';
export const STORE_DETAILS_RESET = 'STORE_DETAILS_RESET';

export const STORE_ROLE_DETAILS_REQUEST = 'STORE_ROLE_DETAILS_REQUEST';
export const STORE_ROLE_DETAILS_SUCCESS = 'STORE_ROLE_DETAILS_SUCCESS';
export const STORE_ROLE_DETAILS_FAIL = 'STORE_ROLE_DETAILS_FAIL';
export const STORE_ROLE_DETAILS_RESET = 'STORE_ROLE_DETAILS_RESET';

export const STORE_LIST_REQUEST = 'STORE_LIST_REQUEST';
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const STORE_LIST_FAIL = 'STORE_LIST_FAIL';

export const STORE_LIST_MANAGER_REQUEST = 'STORE_LIST_MANAGER_REQUEST';
export const STORE_LIST_MANAGER_SUCCESS = 'STORE_LIST_MANAGER_SUCCESS';
export const STORE_LIST_MANAGER_FAIL = 'STORE_LIST_MANAGER_FAIL';

export const STORE_LIST_ROLES_REQUEST = 'STORE_LIST_ROLES_REQUEST';
export const STORE_LIST_ROLES_SUCCESS = 'STORE_LIST_ROLES_SUCCESS';
export const STORE_LIST_ROLES_FAIL = 'STORE_LIST_ROLES_FAIL';

export const PERMISSION_LIST_REQUEST = 'PERMISSION_LIST_REQUEST';
export const PERMISSION_LIST_SUCCESS = 'PERMISSION_LIST_SUCCESS';
export const PERMISSION_LIST_FAIL = 'PERMISSION_LIST_FAIL';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

export const STORE_TOPSELLERS_LIST_REQUEST = 'STORE_TOPSELLERS_LIST_REQUEST';
export const STORE_TOPSELLERS_LIST_SUCCESS = 'STORE_TOPSELLERS_LIST_SUCCESS';
export const STORE_TOPSELLERS_LIST_FAIL = 'STORE_TOPSELLERS_LIST_FAIL';

export const STORE_UPDATE_PROFILE_REQUEST = 'STORE_UPDATE_PROFILE_REQUEST';
export const STORE_UPDATE_PROFILE_SUCCESS = 'STORE_UPDATE_PROFILE_SUCCESS';
export const STORE_UPDATE_PROFILE_FAIL = 'STORE_UPDATE_PROFILE_FAIL';
export const STORE_UPDATE_PROFILE_RESET = 'STORE_UPDATE_PROFILE_RESET';

export const STORE_UPDATE_ROLES_REQUEST = 'STORE_UPDATE_ROLES_REQUEST';
export const STORE_UPDATE_ROLES_SUCCESS = 'STORE_UPDATE_ROLES_SUCCESS';
export const STORE_UPDATE_ROLES_FAIL = 'STORE_UPDATE_ROLES_FAIL';
export const STORE_UPDATE_ROLES_RESET = 'STORE_UPDATE_ROLES_RESET';

export const STORE_UPDATE_REQUEST = 'STORE_UPDATE_REQUEST';
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS';
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL';
export const STORE_UPDATE_RESET = 'STORE_UPDATE_RESET';

export const STORE_DELETE_REQUEST = 'STORE_DELETE_REQUEST';
export const STORE_DELETE_SUCCESS = 'STORE_DELETE_SUCCESS';
export const STORE_DELETE_FAIL = 'STORE_DELETE_FAIL';
export const STORE_DELETE_RESET = 'STORE_DELETE_RESET';

export const STORE_ADDRESS_MAP_CONFIRM = 'STORE_ADDRESS_MAP_CONFIRM';
