export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

export const USER_CREDENTIAL_REQUEST = 'USER_CREDENTIAL_REQUEST';
export const USER_CREDENTIAL_SUCCESS = 'USER_CREDENTIAL_SUCCESS';
export const USER_CREDENTIAL_FAIL = 'USER_CREDENTIAL_FAIL';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const PERMISSION_LIST_REQUEST = 'PERMISSION_LIST_REQUEST';
export const PERMISSION_LIST_SUCCESS = 'PERMISSION_LIST_SUCCESS';
export const PERMISSION_LIST_FAIL = 'PERMISSION_LIST_FAIL';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

export const USER_TOPSELLERS_LIST_REQUEST = 'USER_TOPSELLERS_LIST_REQUEST';
export const USER_TOPSELLERS_LIST_SUCCESS = 'USER_TOPSELLERS_LIST_SUCCESS';
export const USER_TOPSELLERS_LIST_FAIL = 'USER_TOPSELLERS_LIST_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const ACTIVE_USER_TO_SELL_REQUEST = 'ACTIVE_USER_TO_SELL_REQUEST';
export const ACTIVE_USER_TO_SELL_SUCCESS = 'ACTIVE_USER_TO_SELL_SUCCESS';
export const ACTIVE_USER_TO_SELL_FAIL = 'ACTIVE_USER_TO_SELL_FAIL';
export const ACTIVE_USER_TO_SELL_RESET = 'ACTIVE_USER_TO_SELL_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_ADDRESS_MAP_CONFIRM = 'USER_ADDRESS_MAP_CONFIRM';
